/* baskervville-regular - latin */
@font-face {
  font-family: 'Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/baskervville-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Baskervville Regular'), local('Baskervville-Regular'),
       url('/assets/fonts/baskervville-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/baskervville-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/baskervville-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/baskervville-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/baskervville-v1-latin-regular.svg#Baskervville') format('svg'); /* Legacy iOS */
}

:root {
  --black: #1A1A1A;
  --highlighter: rgba(241, 241, 212, 0.8);
  --white: #F5F5F5;

  --font-sans: helvetica, 'helvetica neue', -apple-system, BlinkMacSystemFont, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
  --font-serif: 'Baskerville', serif;
}

* :target {
  background: var(--highlighter);
}

* {
  box-sizing: border-box;
}

html {
  background: var(--white);
  color: var(--black);
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
}

header {
  margin-bottom: 36px;
}

main {
  width: 100%;
  margin: 36px auto;
  max-width: 816px;
  min-width: 320px;
  padding: 0 24px;
}

footer {
  font-size: 0.875em;
  text-align: right;
}

ul {
  list-style: none;
  padding: 0;
}

ul.list-inline li {
  display: inline;
  padding: 3px;
}

h3, h4, h5, h6 {
  line-height: 1.4;
}

p {
  line-height: 1.6em;
}

hr.hr-thick {
  border-top: 4px solid var(--black);
}

ul.index-list {
  border: 1px solid var(--black);
  padding: 18px;
  margin: 48px 0 72px;
}

ul.index-list li {
  margin: 12px 0;
}

.doc,
.doc-page {
  position: relative;
}

.doc__nav,
.doc-page__nav {
  height: 18px;
  position: absolute;
  right: 0;
  top: -30px;
}

.doc__nav-bottom {
  bottom: -30px;
  top: unset;
}

.doc__content {
  font-family: var(--font-serif);
  font-size: 1.1em;
  margin: 48px 0 72px;
}

.text-align-right {
  text-align: right;
}

.margin-0 {
  margin: 0 !important;
}

.margin-left-24 {
  margin-left: 18px !important;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.color-inherit {
  color: inherit;
}

.color-link {
  color: blue;
}

.text-decoration-none {
  text-decoration: none;
}
